
import { Component, Vue } from 'vue-property-decorator'
import MaterialSelect from '@/components/material-select/index.vue'
import { apiGameAppPackSetMealAdd, apiGameAppPackSetMealDetail, apiGameAppPackSetMealEdit } from '@/api/shop'
@Component({
    components: {
        MaterialSelect
    }
})
export default class AddAppPackSetMeal extends Vue {
    $refs!: { form: any }
    aid: any = ''
    id!: any
    loading = false
    form: any = {
        title: '',
        sort: '',
        price: '',
        number: '',
        is_show: 1,
    }

    rules = {
        title: [
            {
                required: true,
                message: '请输入名称',
                trigger: ['blur', 'change']
            }
        ],
      price: [
        {
          required: true,
          message: '请输入价格',
          trigger: ['blur', 'change']
        }
      ],
    }

    validateOption(rule: any, value: any, callback: any) {

        callback()
    }

    handleSave() {
        this.$refs.form.validate((valid: boolean, object: any) => {
            if (valid) {
                const ref = /[^0-9]/g
                if(ref.test(this.form.sort)) {
                    this.$message.error('排序必须是数字')
                    return
                }

                const api = this.id ?
                    apiGameAppPackSetMealEdit({...this.form, aid: this.aid}) :
                    apiGameAppPackSetMealAdd({...this.form, aid: this.aid})
                api.then(() => {
                    this.$router.go(-1)
                })
            } else {
                return false
            }
        })
    }

    getBrandDetail() {
        this.loading = true
      apiGameAppPackSetMealDetail(this.id).then((res: any) => {
            res.option = this.formatOptionFromRes(res.option)
            this.form = res
            this.loading = false
        })
    }

    transformOptionArrToObj() {
        const obj:any = {}
        this.form.option.forEach((e: any, i: any) => {
            obj[i] = e
        })
        return obj
    }

    formatOptionFromRes(option: string[]) {
        return option
        // if (Array.isArray(option)) {
        //     return option
        // }
        // if (option === null || option === undefined) {
        //     option = []
        // }
        // option = Object.keys(option).map((key: any, index: any) => {
        //     const obj = {
        //         name: key,
        //         answer: option[key],
        //     }
        //     return obj
        // })
        // return option
    }

    onOptionItemDeleteBtnClick(item: any, index: any) {
        const list = this.form.option
        list.splice(index, 1)
        this.form.option = list
        this.$refs.form.validateField('option');
    }

    onAddAnswerBtnClick() {
        this.form.option = this.form.option.concat('')
        this.$refs.form.validateField('option');
    }

    created() {
        this.aid = this.$route.query.aid
        this.id = this.$route.query.id
        this.id && this.getBrandDetail()
    }
}
