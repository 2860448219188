import request from '@/plugins/axios'

/** S 商城列表 **/
// 商城列表
export const apiShopLists = (params: any) => request.get('/shop.shop/lists', { params })

// 添加商城
export const apiShopAdd = (params: any) => request.post('/shop.shop/add', params)

// 商城详情
export const apiShopDetail = (params: any) => request.get('/shop.shop/detail', { params })

// 编辑商城
export const apiShopEdit = (params: any) => request.post('/shop.shop/edit', params)

// 删除商城
export const apiShopDelete = (params: any) => request.post('/shop.shop/delete', params)

// 修改超级管理员
export const apiShopChangeSuperAdmin = (params: any) => request.post('/shop.shop/changeSuperAdmin', params)

// 修改商城状态
export const apiShopSwitchStatus = (params: any) => request.post('/shop.shop/switchStatus', params)

// 历史套餐
export const apisetMealLogLists = (params: any) => request.get('/shop.shop/setMealLogLists', { params })

// 修改商城备注
export const apiChangeRemark = (params: any) => request.post('/shop.shop/changeRemark', params)
/** E 商城列表 **/

/** S 游戏应用列表 **/
// 游戏应用列表
export const apiGameAppLists = (params: any) => request.get('/shop.game_app/lists', { params })
// 添加游戏应用
export const apiGameAppAdd = (params: any) => request.post('/shop.game_app/add', params)

// 游戏应用详情
export const apiGameAppDetail = (params: any) => request.get('/shop.game_app/detail', { params })

// 编辑游戏应用
export const apiGameAppEdit = (params: any) => request.post('/shop.game_app/edit', params)

// 删除游戏应用
export const apiGameAppDelete = (params: any) => request.post('/shop.game_app/delete', params)

// 游戏类型列表
export const apiGameAppTypeLists = (params: any) => request.get('/shop.game_app/getGameTypeLists', { params })


export const apiGameUiLists = (params: any) => request.get('/shop.game_ui/getAll', { params })

/** E 游戏应用列表 **/

/** S 套餐列表 **/
// 套餐列表
export const apiSetMealLists = (params: any) => request.get('/shop.set_meal/lists', { params })

// 添加套餐
export const apiSetMealAdd = (params: any) => request.post('/shop.set_meal/add', params)

// 套餐详情
export const apiSetMealDetail = (params: any) => request.get('/shop.set_meal/detail', { params })

// 编辑套餐
export const apiSetMealEdit = (params: any) => request.post('/shop.set_meal/edit', params)

// 删除套餐
export const apiSetMealDelete = (params: any) => request.post('/shop.set_meal/del', params)

// 修改套餐状态
export const apiSetMealSwitchStatus = (params: any) => request.post('/shop.set_meal/status', params)

export const apiMealModule = () => request.get('/shop.set_meal/getMealModule')
/** E 套餐列表 **/

// 游戏配置详情
export const apiGameConfigDetail = (params: any) => request.get('/shop.game_app/gameConfigDetail', { params })

// 修改游戏配置
export const apiGameConfigChange = (params: any) => request.post('/shop.game_app/changeGameConfig', params)

// 获取UI/UI详情
export const apiGameUIDetail = (params: any) => request.get('/shop.game_ui/detail', { params })

// 编辑UI
export const apiGameUIEdit = (params: any) => request.post('/shop.game_ui/edit', params)

// 添加UI
export const apiGameUIAdd = (params: any) => request.post('/shop.game_ui/add', params)

// 修改UI
export const apiGameUIIsShow = (params: any) => request.post('/shop.game_ui/isShow', params)

// UI列表
export const apiGameUILists = (params: any) => request.get('/shop.game_ui/lists', { params })

// 删除UI
export const apiGameUIDelete = (params: any) => request.post('/shop.game_ui/delete', params)

// 打包套餐
export const apiGameAppPackSetMealAdd = (data: any) => request.post('/shop.game_app_package_set_meal/add', data)

// 打包套餐
export const apiGameAppPackSetMealLists = (params: any) => request.get('/shop.game_app_package_set_meal/lists', { params })

// 修改打包套餐状态
export const apiGameAppPackSetMealStatus = (data: any) => request.post('/shop.game_app_package_set_meal/status', data)

// 删除打包套餐
export const apiGameAppPackSetMealDel = (data: any) => request.post('/shop.game_app_package_set_meal/del', data)

// 打包套餐
export const apiGameAppPackSetMealDetail = (id: number) =>
  request.get('/shop.game_app_package_set_meal/detail', { params: { id } })

// 删除打包套餐
export const apiGameAppPackSetMealEdit = (data: any) => request.post('/shop.game_app_package_set_meal/edit', data)

/** S 更多游戏 **/
// 文章/帮助列表
export const apiGameBoxLists = (params: any) => request.get('/shop.game_box_app_list/lists', { params })

// 删除文章/帮助
export const apiGameBoxDelete = (params: any) => request.post('/shop.game_box_app_list/delete', params)

// 获取文章/帮助详情
export const apiGameBoxDetail = (params: any) => request.get('/shop.game_box_app_list/detail', { params })

// 编辑文章/帮助
export const apiGameBoxEdit = (params: any) => request.post('/shop.game_box_app_list/edit', params)

// 添加文章/帮助
export const apiGameBoxAdd = (params: any) => request.post('/shop.game_box_app_list/add', params)

// 修改文章/帮助状态
export const apiGameBoxIsShow = (params: any) => request.post('/shop.game_box_app_list/isShow', params)

// 分类列表
export const apiGameBoxCategoryLists = (params: any) =>
  request.get('/shop.game_box_app_list_category/lists', { params })

// 删除分类
export const apiGameBoxCategoryDelete = (params: any) =>
  request.post('/shop.game_box_app_list_category/delete', params)

// 获取分类详情
export const apiGameBoxCategoryDetail = (params: any) =>
  request.get('/shop.game_box_app_list_category/detail', { params })

// 编辑分类
export const apiGameBoxCategoryEdit = (params: any) =>
  request.post('/shop.game_box_app_list_category/edit', params)

// 添加分类
export const apiGameBoxCategoryAdd = (params: any) => request.post('/shop.game_box_app_list_category/add', params)

// 修改分类状态
export const apiGameBoxCategoryIsShow = (params: any) =>
  request.post('/shop.game_box_app_list_category/isShow', params)
/** E 更多游戏 **/



// 获取UI/UI详情
export const apiGameShopSubjectDeployManagerDetail = (params: any) => request.get('/shop.game_shop_subject_deploy_manager/detail', { params })

// 编辑UI
export const apiGameShopSubjectDeployManagerEdit = (params: any) => request.post('/shop.game_shop_subject_deploy_manager/edit', params)

// 添加UI
export const apiGameShopSubjectDeployManagerAdd = (params: any) => request.post('/shop.game_shop_subject_deploy_manager/add', params)

// 修改UI
export const apiGameShopSubjectDeployManagerIsShow = (params: any) => request.post('/shop.game_shop_subject_deploy_manager/isShow', params)

// UI列表
export const apiGameShopSubjectDeployManagerLists = (params: any) => request.get('/shop.game_shop_subject_deploy_manager/lists', { params })

// 删除UI
export const apiGameShopSubjectDeployManagerDelete = (params: any) => request.post('/shop.game_shop_subject_deploy_manager/delete', params)


